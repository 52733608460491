import request from './http'
//接口文件呢，post请求参数名为data，get请求参数名为params
//合作单位列表
export function cooperationUnitapiList(data) {
    return request({
        url: '/cooperationUnit/apiList',
        method: 'get',
        data:data,
        token:false
    })
}
//汽车行业解决方案分页
export function wxcarIndustryPlanpage(data) {
    return request({
        url: '/wx/carIndustryPlan/page',
        method: 'get',
        data:data,
        token:false
    })
}
//业务领域列表
export function businessAreaapiList(data) {
    return request({
        url: '/businessArea/apiList',
        method: 'get',
        data:data,
        token:false
    })
}

// 关于我们

export function wxaboutdetail(data) {
    return request({
        url: '/wx/about/detail',
        method: 'get',
        data:data,
        token:false
    })
}

//联系我们
export function wxcontactdetail(data) {
    return request({
        url: '/wx/contact/detail',
        method: 'get',
        data:data,
        token:false
    })
}


export function wxhometongji(data) {
    return request({
        url: '/wx/home/tongji',
        method: 'get',
        data:data,
        token:false
    })
}

//经典案例

export function wxclassicCasepage(data) {
    return request({
        url: '/wx/classicCase/page',
        method: 'get',
        data:data,
        token:false
    })
}

//banner
export function wxbannerlist(data) {
    return request({
        url: '/wx/banner/list',
        method: 'get',
        data:data,
        token:false
    })
}

//seo
export function getKeywordsAndDescription(data) {
    return request({
        url: '/wx/getKeywordsAndDescription',
        method: 'get',
        data:data,
        token:false
    })
}


import axios from "axios"  //引入axios
import qs from "qs"  //这个是axios里面的模块，用于序列化参数的。 看情况使用哦
import { getToken,tansParams } from "./auth"   //获取到token
import store from '../store/index'
import routes from '../router/index'
import Vue from 'vue';
let v = new Vue();

//创建一个axios实例
// var base = "/api";
var base = "";
if (process.env.VUE_APP_apiUrl === "http://admin.tj-dasheng.com/api") {
    base = "/api"
} else {
    base = ''
}
const service = axios.create({
    baseURL: base,
    timeout: 50000,
    //transformRequest 这里主要是 post请求时 请求成功了，但是后台并没 
    //有获取到前端的请求参数。如果后台是直接从请求体里取的话，请忽略
    transformRequest: [
        data => {
            let params = qs.stringify(data, { indices: false })
            return params
        }
    ]
})

// 请求拦截器
service.interceptors.request.use(
    config => {
        // console.log(config)
        if (config.token == true) {
            config.data.vendorToken = getToken()
            config.data.wxToken = getToken()
        }
        // get请求映射params参数
        if (config.method === 'get' && config.data) {
            let url = config.url  + tansParams(config.data);
            config.data = {};
            config.url = url;
        }
        // console.log(config)
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

//响应拦截器
service.interceptors.response.use(
    response => {
        const res = response.data
        // console.log(res)
        let that = this;
        if (res.code != '00000') {  
            //这里主要是判断code值 等于什么，代表着token值失效 例如：50008
            if (res.code == '401') {
                // store.commit("updataLogin");
                localStorage.clear()
                v.$message.error('您没有登录或登录过期，请重新登录');
               
               setTimeout(res=>{
                //location.reload();
                routes.push('/login');
               },1000)
               
                
            }else if(res.code == '500'){
                v.$message.error(res.message);
               
            }else if(res.code == '408'){
                //408不报错误信息
               // v.$message.error(res.message);
            }else {
                v.$message.error(res.message);
            }
            return res;
        } else {
            return res
        }
    },
    error => {
        const res = error.data
        // console.log(error)
        v.$message.error('系统故障');
        return Promise.reject(error)
    }
)

export default service
<template>
  <div>
    <!-- <h1>{{ title }}</h1> -->
  </div>
</template>

<script>
export default {
  name: "tit",
  data() {
    return {
      title: "大圣科技",
      description: "",
      keywords:''
    };
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { vmid: "description", name: "description", content: this.description },
        { vmid: "keywords", name: "keywords", content: this.keywords },
      ],
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.$api
        .getKeywordsAndDescription({})
        .then((res) => {
         this.description = res.data.baidu_description;
         this.keywords = res.data.baidu_keywords
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
const TokenKey = 'vendorToken'

export function getToken() {

    return localStorage.getItem(TokenKey)
}

export function setToken(token) {
    return localStorage.setItem(TokenKey, token)

}

export function removeToken() {
    return localStorage.clear()
}
export function sethome(data) {
    return localStorage.setItem('homeList', data)

}
export function gethome() {

    return localStorage.getItem('homeList')
}


export function tansParams(param) {
    return JSON.stringify(param).replace(/:/g,'=').replace(/,/g,'&').replace(/{/g,'?').replace(/}/g,'').replace(/"/g,'');
}
export function getUrl() {
    return 'http://caericares.tj-dasheng.com'
    //return 'http://wxapi.caericares.com'
}
export function getuploadurl() {//图片下载
    return getUrl()+'/wx/uploadImg'
}
export function qrcode() {//邀请抱团 二维码链接
    //return 'http://cza.tj-dasheng.com/wx/yqbt'
    return 'http://caericares.com/wx/yqbt'
}



export function isPoneAvailable(poneInput) {
    var myreg=/^[1][3,4,5,7,8][0-9]{9}$/;
    console.log()
    if (!myreg.test(poneInput)) {
        return false;
    } else {
        return true;
    }
}


<template>
  <div id="app">
    <div id="nav">
      <!-- <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> -->
      <Menu></Menu>
      <router-view></router-view>
      <bottom></bottom>
      <tit></tit>
    </div>
  </div>
</template>
<script>
import Menu from "./components/menu/menu";
import bottom from "./components/menu/bottom";
import tit from "./components/tit.vue"
export default {
  components:{
    Menu,bottom,tit
  },
  watch:{
    '$route':function(to,from){
　    document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    }
  }
}   
</script>
<style lang="less">
#app {
  // font-family: MicrosoftYaHei;
font-size: 18px;
color: #333333;
letter-spacing: 0.61px;
margin-top: 90px;
}
body{
    margin: 0;
  padding: 0;
}

</style>

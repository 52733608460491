<template>
<div class="contentLeft" >
    <div class="mytabs">
      <div class="tabs">
      <!-- <div class="tabsTitles">
        <div v-for=" (item,index) in titlist" :key="index" :class="listType==index?'act':''" @click="add(index,item.link)">{{item.tit}}</div>
      </div> -->
      <img src="../../assets/logo.png" alt="" class="imgs">
        <el-row class="tac sss">
        <el-col>
          <el-menu
            :default-active="$route.path"
            class="el-menu-vertical-demo"
            @select ="handselect"
            mode="horizontal" 
          >
          <template >
             <el-menu-item index="/">
              <span slot="title" class="cszl">首页</span>
            </el-menu-item>
            <el-menu-item index="/ywly">
              <span slot="title" class="cszl">业务领域</span>
            </el-menu-item>
               <el-menu-item index="/jdal">
              <span slot="title" class="cszl">经典案例</span>
            </el-menu-item>
            <el-menu-item index="/jjfa">
              <span slot="title" class="cszl">汽车行业解决方案</span>
            </el-menu-item>
               <el-menu-item index="/hzdw">
              <span slot="title" class="cszl">合作单位</span>
            </el-menu-item>
            <el-menu-item index="/gywm">
              <span slot="title" class="cszl">关于我们</span>
            </el-menu-item>
               <el-menu-item index="/lxwm">
              <span slot="title" class="cszl">联系我们</span>
            </el-menu-item>
          </template>   


          </el-menu>
        </el-col>
      </el-row>
    </div>
    </div>
      <!-- <el-row class="tac">
        <el-col>
          <el-menu
            :default-active="$route.path"
            class="el-menu-vertical-demo"
            @select ="handselect"
            mode="horizontal" 
          >
          <template >
             <el-menu-item index="/">
              <span slot="title" class="cszl">首页</span>
            </el-menu-item>
            <el-menu-item index="/zazx">
              <span slot="title" class="cszl">质安资讯</span>
            </el-menu-item>
               <el-menu-item index="/zats">
              <span slot="title" class="cszl">质安投诉</span>
            </el-menu-item>
            <el-menu-item index="/zagc">
              <span slot="title" class="cszl">质安观察</span>
            </el-menu-item>
               <el-menu-item index="/zhdt">
              <span slot="title" class="cszl">召回动态</span>
            </el-menu-item>
            <el-menu-item index="/zabg">
              <span slot="title" class="cszl">质安报告</span>
            </el-menu-item>
               <el-menu-item index="/zapc">
              <span slot="title" class="cszl">质安评测</span>
            </el-menu-item>
            <el-menu-item index="/zady">
              <span slot="title" class="cszl">质安答疑</span>
            </el-menu-item>
          </template>   


          </el-menu>
        </el-col>
      </el-row> -->
    </div>

 
</template>

<script>
export default {
     data() {
    //这里存放数据
    return {
     titlist: [
        { tit: "首页" ,link:'/'},
        { tit: "质安资讯",link:'/zazx' },
        { tit: "质安投诉",link:'/zats' },
        { tit: "质安观察",link:'/zagc' },
        { tit: "召回动态",link:'/zhdt' },
        { tit: "质安报告" ,link:'/zabg'},
        { tit: "质安评测",link:'/zapc' },
        { tit: "质安答疑",link:'/zady'},
      ],
      listType:0
    };
  },
    methods: {
        add(index,link){

          // if(index == this.listType){
          //     return false
          // }else{
          //     this.listType = index
          //     this.$router.push(link)
          // }
           this.listType = index
              this.$router.push(link)
      },
    handselect(key, keyPath,index) {
      if(this.$route.path!=key){
         this.$router.push(key);
        this.handleselectIndex = key
      }
      },
  },
  mounted(){
   
    }
}
</script>
<style >
  .contentLeft .el-submenu__title{
    font-size: 17px;
    padding-left: 27px !important; 
  }
  .sss .el-menu-item{
        padding: 0 30px !important;
  }
  .sss .el-menu{
    /* background: #19429f !important; */
    /* color: #fff !important; */
  }
  .sss .el-menu.el-menu--horizontal{
    border-bottom: none !important;
  }
  .sss .el-menu-item{
    border: none !important;
    color: #333 !important;
  }
  .sss .is-active{
      background: #237BFF !important;
      font-weight: bold !important;
      color: #fff !important
  }
  .sss .el-menu--horizontal .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal .el-menu-item:not(.is-disabled):hover{
     background: #237BFF !important;
     color: #fff !important;
  }
  .sss .el-menu--horizontal>.el-menu-item{
      height: 90px !important;
      line-height: 90px !important;
  }
</style>
<style lang='less' scoped>
.imgs{
    width: 76px;
    height: 60px;
    display: block;
    margin-top: 20px;
}
.tac{
  width: 1000px;
}
.mylist{
  cursor:pointer;
}
.cszl{
   font-size: 17px;
//    color: #333;
}
.content {
  width: 1100px;
  margin: auto;
  display: flex;
  .contentLeft {
    width: 224px;
    min-height: 92.5vh;
    background: #fff;
  }
  .tx {
    // width: 86px;
    height: 139px;
    margin:48px auto;
    img {
      width: 86px;
      height: 86px;
       border-radius:43px;
      display: block;
      margin: auto;
      margin-bottom: 12px;
    }
    text-align: center;
    font-size: 20px;
    color: #333333;
  }
}

.mylist {
  div {
    display: flex;
    // border-left: 5px solid #fff;
    // margin-bottom: 44px;
     height: 56px;
    line-height: 56px;
    // cursor: pointer;
    img {
      display: block;
      width: 18px;
      height: 18px;
      padding-left: 25px;
      padding-right: 10px;
      margin-top: 18px;
    }
  }
}
.img2{
   width: 18px;
      height: 18px; 
      padding-right: 10px;
}
.bg{
    background: #fff;
}
.tabs {
  width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
.mytabs{
width: 100%;
  margin: auto;

  position: fixed;
  top: 0;
  z-index: 111;
  background: #fff;
}
.tabsTitles {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin: auto;
  font-size: 16px;
  color: #ffffff;
  div {
    cursor: pointer;
    padding: 0 18px;
  }
}
.contens1{
    width: 1200px;
    margin: auto;
}
.act{
background: #123689 !important;
}
  .con {
    width: 1200px;
    margin: auto;
    padding: 30px 0 34px;
    .tt {
      font-family: PingFangSC-Medium;
      font-size: 22px;
      color: #333333;
      letter-spacing: 1.56px;
      margin-bottom: 20px;
    }
    .xx {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #333333;
      letter-spacing: 0.78px;
      margin-top: 10px;
    }
  }
  .bottom {
  min-height: 234px;
  background: #f6f6f6;
  margin-top: 52px;
}
</style>


import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: resolve => require(['@/views/Home'],resolve) 
  },
  {
    path: "/ywly",
    name: "ywly",
    component: resolve => require(['@/views/ywly'],resolve) 
  },
  {
    path: "/jdal",
    name: "jdal",
    component: resolve => require(['@/views/jdal'],resolve) 
  },
  {
    path: "/lxwm",
    name: "lxwm",
    component: resolve => require(['@/views/lxwm'],resolve) 
  },
  {
    path: "/hzdw",
    name: "hzdw",
    component: resolve => require(['@/views/hzdw'],resolve) 
  },
  {
    path: "/jjfa",
    name: "jjfa",
    component: resolve => require(['@/views/jjfa'],resolve) 
  },
  {
    path: "/gywm",
    name: "gywm",
    component: resolve => require(['@/views/gywm'],resolve) 
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;





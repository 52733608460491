<!--  -->
<template>
	<div class='bottom'>
		<div class="bottcontent">
			<img src="../../assets/logo1.png" alt="" srcset="" class="logo1">
			<div class="centit">
				<div class="fa">专注汽车行业一站式解决方案</div>
				<div class="bq">Copyright © 2022 天津大圣科技发展有限公司版权所有 <a
						href="https://beian.miit.gov.cn/" target="_blank">津ICP备2021005238号-1</a></div>
			</div>
			<div class="myright">
				<div>
					<div class="ww">
						<img :src="'http://tj-dasheng.com/api/sysFileInfo/public/preview?fileId='+item.wx1" alt="">
					</div>
					微信一
				</div>
				<div>
					<div class="ww"><img :src="'http://tj-dasheng.com/api/sysFileInfo/public/preview?fileId='+item.wx2"
							alt=""></div>
					微信二
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
	//例如：import 《组件名称》 from '《组件路径》';

	export default {
		//import引入的组件需要注入到对象中才能使用
		components: {},
		data() {
			//这里存放数据
			return {
				item: ''
			};
		},
		//监听属性 类似于data概念
		computed: {},
		//监控data中的数据变化
		watch: {},
		//方法集合
		methods: {

		},
		//生命周期 - 创建完成（可以访问当前this实例）
		created() {

		},
		//生命周期 - 挂载完成（可以访问DOM元素）
		mounted() {
			this.$api
				.wxcontactdetail({})
				.then((res) => {
					// this.hzdwcontent = res.data
					this.item = res.data
				})
				.catch((err) => {
					console.log(err);
				});
		},
		beforeCreate() {}, //生命周期 - 创建之前
		beforeMount() {}, //生命周期 - 挂载之前
		beforeUpdate() {}, //生命周期 - 更新之前
		updated() {}, //生命周期 - 更新之后
		beforeDestroy() {}, //生命周期 - 销毁之前
		destroyed() {}, //生命周期 - 销毁完成
		activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
	}
</script>
<style lang='less' scoped>
	.bottom {
		height: 194px;
		background: #242529;
		width: 100%;

		.bottcontent {
			width: 1200px;
			margin: auto;
			display: flex;
			justify-content: space-between;
			padding-top: 47px;
		}

		.logo1 {
			width: 123px;
			height: 90px;
		}

		.centit {
			font-size: 18px;
			color: #FFFFFF;
			letter-spacing: 1.03px;
		}

		.myright {
			width: 200px;
			display: flex;
			justify-content: space-between;
			font-size: 12px;
			color: #FFFFFF;
			letter-spacing: 0.68px;
			text-align: center;

			.ww {
				width: 88px;
				height: 88px;
				margin-bottom: 10px;

				img {
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	.fa {
		font-size: 18px;
		color: #FFFFFF;
		letter-spacing: 1.03px;
		margin-top: 33px;
	}

	.bq {
		font-size: 12px;
		color: #FFFFFF;
		letter-spacing: 0.68px;
		margin-top: 44px;
		text-align: center;

		a {
			color: #FFFFFF;
			text-decoration: none;
		}
	}
</style>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import  * as api from './request/user'
import * as tokenList from './request/auth'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './request/beforeEach'
import Meta from 'vue-meta';
Vue.use(Meta)
Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.prototype.$api = api
Vue.prototype.$tokenList = tokenList

import VueAwesomeSwiper from 'vue-awesome-swiper'
//引入样式
import 'swiper/css/swiper.css'
 
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
